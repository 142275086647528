


























































import {Vue, Component} from "vue-property-decorator";
import {DynamicMess} from "@/request/webDomain/DynamicMess";

@Component({
    name: 'webDomain_dynamicMess'
})
export default class webDomain_dynamicMess extends Vue {
    modal: any = DynamicMess;
    formData: any = {
        title: '',
        cover: '',
        summary: '',
        content: ''
    };
    formRules: any = {
        title: {required: true, trigger: 'blur'},
        cover: {required: true, trigger: 'blur'},
        summary: {required: true, trigger: 'blur'},
        content: {required: true, trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '标题',
            key: 'title',
            minWidth: 160
        },
        {
            title: '摘要',
            key: 'summary',
            tooltip: true,
            minWidth: 200
        },
        {
            title: '创建时间',
            key: 'createTime',
            width: 180
        }
    ];
    created() {

    }
    createNew() {
        let self: any = this;
        delete this.formData.id;
        self.$refs['el-editor'].setHtml('');
    }
    edit(row: any) {
        let self: any = this;
        this.formData = row;
        self.$refs['el-editor'].setHtml(row.content);
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
}
